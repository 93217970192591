(function () {
    // Create the noscript element
    var script = document.createElement("script");
    script.id = "Cookiebot";
    script.src = "https://consent.cookiebot.com/uc.js";
    script.setAttribute("data-cbid", "f2c4ed23-7b01-425c-95cc-7116eda56395");
    script.setAttribute("data-blockingmode", "auto");
    script.type = "text/javascript";
    if (import.meta.env.VITE_REGION == "EU") {
        document.head.appendChild(script);
    }
})();
